.App {
  text-align: center;
}

body {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.heading {
  background-image: url('./Assets/EVStation_Cam11eyeview_2505.png');
  background-color: #cccccc;
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}

.runing-car {
  position: relative;
  animation: movement 5s linear infinite;
  -webkit-animation: movement 5s linear infinite;
  left: 0;

  height: 60px;
  width: 150px;

}

.p2 {
  font-size: 1vw;
  color: black;
  font-family: "Copperplate Gothic";
}

.join {
  /* display: grid;imp */
  height: fit-content;
  justify-content: center;

  align-items: center;


}

.border {
  margin: 0px;
  background-color: black;
  border-color: black;
  border: none;
  height: 0.5px;

}

/* moving car */
.runingcar {


  position: absolute;
  animation: movement 5s linear infinite;
  -webkit-animation: movement 5s linear infinite;
  left: 0;

  height: 60px;
  width: 150px;

}

@keyframes movement {
  100% {
    left: 100%;
  }

}

@-webkit-keyframes movement {
  100% {
    left: 100%;
  }
}

/* <div style="padding-top:75.000%;position:relative;"><iframe src="https://gifer.com/embed/VdgG" width="100%" height="100%" style='position:absolute;top:0;left:0;' frameBorder="0" allowFullScreen></iframe></div><p><a href="https://gifer.com">via GIFER</a></p> */


.p3 {
  font-size: 2vw;
  color: rgb(6, 181, 76);
  margin-top: -1rem;
  padding-left: 2vw;
  font-family: "Segoe Script", "Courier New", monospace;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 250px;
  height: 100px;
}

.bar {
  float: right;
}

.pic {
  max-width: -webkit-fill-available;
}

.border1 {}

@media screen and (max-width: 1200px) {
  .logo {
    width: 175px;
    height: 65px;
  }

  .logo1 {
    display: none;
  }

}

.card {

  padding: 50px;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.form-control {
  padding: 2rem;

}

input {
  width: 16rem;

}

table {
  border: 2px solid rgb(6, 181, 76);
  width: auto;
  max-width: max-content;
  /* border-radius: 50px 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px;
  margin: auto;

}

.hi {
  max-width: 390px;
  padding: 10px;

  grid-auto-flow: column;
  column-gap: 1rem;
  overflow-x: auto;
}

.hi::-webkit-scrollbar {
  display: none;

}

.hi-item {
  position: relative;
  width: 100px;
  height: 100px;
  border: 4px solid #151515;
  border-radius: 50%;

}

.hi-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.hi-item::before {
  contain: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  background-image: linear-gradient(45deg, #f09433 0%, #e6683c 25%,
      #dc2743 50%, #6902be 75%,
      #bc1888 100%);
  border-radius: inherit;
  z-index: -1;
}